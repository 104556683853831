<template>
  <v-card elevation="6" style="border-radius: 0px">
    <v-card-title :style="fontStyles.fontFamilyFuturaSubTitle">
      {{ this.$t("contact-us") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent>
        <v-row>
          <v-col cols="6">
            <v-text-field
              hide-details
              rounded="0"
              class="border-orange"
              v-model="nom"
              :counter="10"
              :label="this.$t('name') + ' *'"
              :rules="globalRules"
              required
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              hide-details
              rounded="0"
              class="border-orange"
              v-model="prenom"
              :counter="10"
              :label="this.$t('firstname') + ' *'"
              required
              :rules="globalRules"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              hide-details
              rounded="0"
              class="border-orange"
              v-model="select"
              :items="items"
              :label="this.$t('select-an-object') + ' *'"
              required
              :rules="globalRules"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              rounded="0"
              v-model="message"
              :label="this.$t('message') + ' *'"
              required
              :rules="globalRules"
              :style="fontStyles.fontFamilyEgyptienne"
            ></v-textarea>
          </v-col>
        </v-row>
        <small
          :style="fontStyles.fontFamilyFuturaButton"
          class="text-caption text-medium-emphasis"
        >
          {{ $t("require-fields") }}
        </small>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        rounded="0"
        variant="flat"
        color="#395d5b"
        :prepend-icon="mdiSend"
        class="mb-5"
        type="submit"
        @click="send"
        :loading="loading"
        :style="fontStyles.fontFamilyFuturaButton"
      >
        {{ $t("send") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiSend } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";
import { mapState } from "vuex";

export default {
  name: "ContactForm",
  mixins: [fontMixin],
  data() {
    return {
      globalRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ],
      mdiSend,
      nom: "",
      prenom: "",
      message: "",
      items: ["Commercial", "Technique", "Autre"],
      select: null
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth,
      customer: (state) => state.users.selectedCustomer.id,
      loading: (state) => state.email.loading
    })
  },
  methods: {
    send() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        this.$store
          .dispatch("email/sendEmail", {
            nom: this.nom,
            prenom: this.prenom,
            select: this.select,
            customer: this.customer,
            message: this.message
          })
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.email"),
              color: "success"
            });
            this.resetForm();
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.email"),
              color: "error"
            });
          });
      } else {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("alert.error.email-validation"),
          color: "error"
        });
      }
    },
    resetForm() {
      this.nom = "";
      this.prenom = "";
      this.select = null;
      this.message = "";
    }
  }
};
</script>

<style></style>
