<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('firstname') + ' *'"
                v-model="editedItem.firstName"
                :rules="firstnameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('name') + ' *'"
                v-model="editedItem.lastName"
                :rules="lastnameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('email') + ' *'"
                v-model="editedItem.email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6" v-if="specificRoles == ''">
              <v-select
                v-model="editedItem.fK_UserRole"
                :items="roles"
                item-title="name"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-checkbox
                label="Actif"
                color="#ffb500"
                v-model="editedItem.active"
                :true-value="true"
                :false-value="false"
              ></v-checkbox>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "AddEditUser",
  props: ["modelDialog", "specificRoles"],
  data() {
    return {
      mdiPencil,
      mdiAccount,
      lastnameRules: [(v) => !!v || this.$t("rules.required")],
      firstnameRules: [(v) => !!v || this.$t("rules.required")],
      emailRules: [
        (v) => !!v || this.$t("rules.required"),
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$t("rules.email-valid")
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.users.editedId,
      roles: (state) => state.roles.items
    }),
    editedItem: {
      get() {
        return this.$store.state.users.editedItem;
      },
      set(value) {
        this.$store.commit("users/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-user")
        : this.$t("admin.edit-user");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        if (this.editedId === -1) {
          this.$store
            .dispatch(
              "users/saveEditedItem",
              this.specificRoles == ""
                ? this.editedItem.fK_UserRole
                : this.specificRoles
            )
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.user-create"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.user-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch(
              "users/updateEditedItem",
              this.specificRoles == ""
                ? this.editedItem.fK_UserRole
                : this.specificRoles
            )
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.user-update"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.user-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
