<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-card-title :style="fontStyles.fontFamilyFutura" class="pb-5">
        <div class="pb-2">Déclaration de confidentialité</div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Introduction
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Stryker reconnaît que la protection des données à caractère personnel
          qui lui sont communiquées est primordiale. Stryker cherche à
          satisfaire cette confiance en adhérant aux principes généraux
          concernant la protection des données à caractère personnel. Cette
          déclaration de confidentialité explique comment nous collectons,
          utilisons, partageons et protégeons les données dans le cadre de
          l'exploitation de notre entreprise.
        </div>
        <!-- <br class /> -->
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Champ d’application
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            La présente déclaration de confidentialité (« Déclaration »)
            s’applique aux données à caractère personnel des clients, collectées
            ou utilisées par Stryker, ses sociétés affiliées ou ses filiales
            (collectivement, « Stryker », « nous », « notre », « nos »). La
            présente déclaration s’applique à toutes les données à caractère
            personnel que Stryker collecte lorsque ses clients interagissent
            avec nous, par exemple lorsqu’ils visitent ses sites Web, achètent
            ou utilisent nos produits et services, communiquent avec le service
            à la clientèle et interagissent avec nous en tant que fournisseurs,
            clients ou partenaires commerciaux (collectivement, les « services
            »).
          </div>
          <div class="pb-3">
            Cela comprend, sans s’y limiter, la collecte en ligne et hors ligne
            de tous types de données à caractère personnel. Toutefois, certaines
            collectes de Stryker concernent des types de données impliquant des
            exigences particulières (p. ex., informations sur la santé), qui
            requièrent une déclaration de confidentialité distincte. Dans ce
            cas, Stryker précisera clairement que la déclaration concernée
            diffère de la présente déclaration.
          </div>
          Pour les personnes résidant en Californie, prière de consulter notre
          Avis de confidentialité pour les résidents de Californie pour plus
          d'informations sur nos pratiques.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Collecte d’informations
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Stryker peut vous demander de fournir des données à caractère
            personnel aux fins qui suivent, sans s’y limiter :
          </div>
          <ul class="pl-5 pb-3">
            <li>Achat de produits et de services Stryker</li>
            <li>
              Activation ou enregistrement de certains produits et services ou
              amélioration d’une fonctionnalité
            </li>
            <li>
              Obtention de renseignements sur les produits et services Stryker
            </li>
            <li>
              Participation aux communautés Stryker en ligne, notamment à nos
              pages et canaux sur les réseaux sociaux
            </li>
            <li>
              Conservation de vos préférences pour vos interactions et
              communications futures avec Stryker
            </li>
            <li>
              Nous aider à créer des produits, des services et des campagnes qui
              vous sont destinés, optimiser les services à la clientèle et
              améliorer en permanence les sites Web Stryker
            </li>
            <li>
              Nous aider à améliorer les produits et les services et permettre à
              Stryker de vous tenir informé des nouveaux produits et services ou
              vous faire participer aux tests de ces produits et services
            </li>
            <li>
              Résolution des problèmes soulevés par les clients et/ou affectant
              les produits et les services
            </li>
            <li>
              Enregistrement des visiteurs des installations de Stryker ou
              assistant aux conférences ou événements organisés par Stryker
            </li>
            <li>Gestion des contrats ou des appels d’offres et</li>
            <li>
              Réception de messages personnalisés, d’offres spéciales et de
              publicités liés à vos centres d’intérêt personnels, selon les
              renseignements communiqués à Stryker et les informations que nous
              recueillons par le biais de cookies ou de techniques similaires
              concernant votre utilisation des sites Web, des réseaux sociaux et
              des blogues de Stryker
            </li>
          </ul>
          <div class="pb-3">
            Stryker maintient des dispositifs de protection physiques,
            techniques et administratifs de vos données à caractère personnel et
            ne divulgue que les informations autorisées par la loi pour l’aider
            à fournir des produits et des services. Stryker peut également
            recueillir des renseignements sur les produits et les services et
            fournir ces statistiques sous forme anonymisée et consolidée à
            d’autres entités.
          </div>
          <div class="pb-3">
            Les données à caractère personnel collectées peuvent inclure :
          </div>
          <ul class="pl-5 pb-3">
            <li>
              Les coordonnées personnelles, comme le nom, l’adresse postale,
              l'adresse e-mail, le numéro de téléphone, le numéro de télécopie,
              le nom de l’entreprise et/ou le titre du poste
            </li>
            <li>
              Les identifiants et préférences uniques, comme le nom
              d’utilisateur, le mot de passe, les préférences de marketing,
              l’adresse IP, le type de navigateur, le système d’exploitation,
              l’ordinateur ou l’appareil mobile ou le parcours de navigation et
              les clics lors des interactions en ligne
            </li>
            <li>
              Le curriculum vitæ, notamment l’expérience professionnelle, les
              qualifications professionnelles, les publications, les
              récompenses, les références, les formations suivies et la
              signature
            </li>
            <li>
              Les restrictions alimentaires, les informations de passeport aux
              fins d’identification ou de réservation de voyages, le numéro de
              sécurité sociale (lorsque la loi l’exige), les coordonnées
              bancaires, les personnes à contacter (en cas d’urgence), les
              informations familiales (le cas échéant et conformément à la
              législation locale)
            </li>
            <li>
              Les informations de solvabilité, le numéro de TVA, l’historique
              des renseignements sur les produits et les achats, la
              documentation liée aux demandes
            </li>
            <li>
              Les images et enregistrements vidéo et audio pour lesquels vous
              avez accordé votre permission
            </li>
            <li>
              Des données à caractère personnel sensibles, comme les
              informations ou rapports médicaux d’un client final particulier
              (p. ex. concernant les réclamations et enquêtes portant sur des
              produits, les résultats pré et postopératoires d’un patient aux
              fins de recherches et de statistiques ou les casiers judiciaires
              aux fins des procédures de diligence raisonnable conformément à la
              législation en vigueur).
            </li>
          </ul>
          <div class="pb-3">
            Stryker conservera et utilisera les données à caractère personnel
            recueillies aux fins de traitement susmentionnées conformément aux
            obligations légales.
          </div>
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Fondement juridique
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Le fondement juridique sur lequel s’appuie le traitement des données
            à caractère personnel par Stryker dépendra des objectifs du
            traitement et des exigences légales des instances concernées. Si les
            données à caractère personnel collectées concernent des citoyens de
            l’Union européenne, Stryker se basera généralement sur les
            fondements suivants :
          </div>
          <ul class="pl-5 pb-3">
            <li>
              Consentement à l’utilisation des renseignements personnels, p. ex.
              lorsque celle-ci implique des activités de marketing direct
              (numériques) et lorsque vous souhaitez diffuser à l’extérieur une
              photo ou un enregistrement vidéo ou audio où figurent des
              personnes identifiables
            </li>
            <li>
              Conclusion ou exécution d’un contrat, p. ex. lorsque Stryker
              conclut un contrat avec une autre partie et collecte des données à
              caractère personnel et des signatures, en plus, éventuellement,
              des coordonnées bancaires d’un représentant d’une entreprise avec
              laquelle nous faisons affaire
            </li>
            <li>
              Respect d’une obligation légale, p. ex. si Stryker doit divulguer
              les coordonnées de clients pour répondre aux exigences de lois
              (locales ou sectorielles) comme le Physician Payments Sunshine Act
              ou aux exigences de la réglementation sur les dispositifs médicaux
            </li>
            <li>
              Intérêt légitime de Stryker, p. ex. lorsque la société collecte
              des coordonnées et des préférences, des renseignements sur
              l’hébergement et le déplacement de participants à des événements
              et formations organisés par Stryker ou organisés par un tiers en
              collaboration avec Stryker
            </li>
            <li>
              Intérêt public ou exercice de l’autorité publique dans des cas
              strictement limités, p. ex. des cas de comportement illicite
              exigeant notre collaboration avec la police ou d’autres organismes
              publics
            </li>
            <li>
              Dépôt, règlement ou défense d’une réclamation fondée en droit, p.
              ex. dans le cadre de procédures judiciaires
            </li>
            <li>
              À des fins scientifiques ou de recherche, p. ex. des études
              cliniques présentant des mécanismes suffisants pour la protection
              de la confidentialité
            </li>
            <li>
              Médecine préventive et du travail, p. ex. diagnostic médical dans
              le cadre d’un contrat conclu avec des professionnels de santé
            </li>
            <li>
              Intérêt public en matière de santé publique, afin de garantir des
              normes de qualité et de sécurité élevées régissant les soins de
              santé et les produits et
            </li>
            <li>
              Lorsqu’une personne choisit de divulguer au grand public ses
              données à caractère personnel de son propre chef
            </li>
          </ul>
          <div class="pb-3">
            Si vous décidez de ne pas fournir les données à caractère personnel
            raisonnablement demandées par Stryker, cela peut empêcher la société
            de vous fournir les informations ou les services que vous avez
            demandés
          </div>
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Transferts transfrontaliers
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Dans la mesure où des données à caractère personnel sont transférées
          hors du pays de résidence du propriétaire de ces données, comme à
          l’intention des filiales ou partenaires commerciaux de Stryker situés
          dans d’autres pays, y compris les États-Unis, des normes différentes
          peuvent s’appliquer à la façon dont vos données sont utilisées et
          protégées dans ces pays. Stryker a mis en place des mécanismes de
          protection adéquats, conformément aux exigences légales en vigueur,
          pour veiller à la protection adéquate des données, sans égard au pays.
          Ces mécanismes comprennent notamment l’obtention de garanties écrites
          de tout tiers qui se voit accorder l’accès à vos données et
          l’obligation d’adopter des normes assurant un degré de protection
          équivalant à celui fourni par Stryker, ainsi que des mécanismes de
          protection et des mesures contractuelles normalisés (selon les clauses
          types de la Commission européenne) pour les transferts de données
          internes aux filiales de Stryker situées dans des pays tiers réputés
          fournir un niveau inadéquat de protection des données.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Déclaration de confidentialité concernant les enfants
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Stryker ne recueillera aucun renseignement personnel auprès de toute
          personne âgée de moins de 16 ans sans le consentement préalable et
          vérifiable de son tuteur légal. Ce tuteur légal a le droit, sur
          demande, de consulter les renseignements fournis par l’enfant et/ou
          d’exiger leur suppression.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Conservation des données
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Stryker conservera vos données à caractère personnel aussi longtemps
          qu’elles lui seront raisonnablement nécessaires pour se conformer à
          ses obligations légales ou parvenir à ses fins commerciales légitimes.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Divulgation des données
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Stryker pourrait échanger les renseignements personnels collectés
            avec des entreprises ou des agents spécialisés en maintenance
            technologique ou travaillant pour le compte de Stryker pour
            satisfaire ses besoins opérationnels, notamment la prestation de
            services à la clientèle et la distribution de communications
            marketing. Stryker pourrait également échanger des données à
            caractère personnel avec ses sociétés affiliées et ses filiales.
          </div>
          <div class="pb-3">
            Stryker peut partager vos données à caractère personnel avec
            d’autres fournisseurs de services retenus pour assumer certaines
            fonctions pour le compte de Stryker, notamment des centres d’appels
            ou des prestataires de services d’expédition ou de traitement de
            courrier postal ou électronique, ou d’analyse et d’hébergement de
            données sur des serveurs basés sur le cloud.
          </div>
          <div class="pb-3">
            Stryker n’échange pas vos données à caractère personnel avec des
            tiers non affiliés en vue de réaliser leurs propres objectifs
            marketing.
          </div>
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Sécurité
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          La sécurité et la confidentialité de vos données à caractère personnel
          nous importent. Pour cette raison, Stryker a mis en place des
          contrôles physiques, techniques et administratifs afin de protéger vos
          données à caractère personnel contre tout accès, utilisation et
          divulgation non autorisés. Stryker évalue sans cesse ces mécanismes de
          protection afin de minimiser les risques résultant de nouvelles
          menaces à la sécurité portées à sa connaissance.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Droits
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Vous pouvez demander des précisions sur vos données à caractère
            personnel en possession de notre société. Si vous pensez que les
            données à caractère personnel en notre possession vous concernant
            sont incorrectes ou incomplètes, veuillez nous contacter dès que
            possible, à l'adresse ci-dessous. Si vous pensez que l’un de ces
            renseignements est incorrect, veuillez nous en informer dans les
            plus brefs délais.
          </div>
          <div class="pb-3">
            Vous pouvez toujours choisir de vous opposer à la collecte ou à
            l'utilisation de vos données à caractère personnel ou de faire
            effacer vos données. Si vous souhaitez une copie des données que
            nous détenons à votre sujet pour votre usage personnel ou pour les
            transférer à une autre partie, ou si vous souhaitez exercer tout
            autre droit, veuillez nous contacter à l'adresse
            <a href="mailto:globalprivacy@stryker.com"
              >globalprivacy@stryker.com</a
            >.
          </div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaVerySmall">
          <a
            href="https://www.stryker.com/us/en/legal/privacy/privacy-notice-for-california-residents.html"
            style="color: #2f4d4c"
          >
            SI VOUS RÉSIDEZ EN CALIFORNIE, VEUILLEZ CONSULTER NOTRE AVIS DE
            CONFIDENTIALITÉ POUR LES RÉSIDENTS DE CALIFORNIE POUR PLUS
            D'INFORMATIONS CONCERNANT VOS DROITS ET VOS CHOIX.
          </a>
        </div>
        <div :style="fontStyles.fontFamilyFuturaVerySmall" class="pb-3 pt-5">
          Mises à jour de la déclaration de confidentialité
        </div>
        <div :style="fontStyles.fontFamilyHumanistBold" class="pb-5">
          La présente déclaration de confidentialité peut être modifiée à tout
          moment. Si des modifications importantes sont apportées à la méthode
          de collecte, d’utilisation, de divulgation ou de tout autre traitement
          des données à caractère personnel, la présente déclaration sera mise à
          jour et des avis seront communiqués en temps et en lieu opportuns.
          Toute modification importante de la présente déclaration entrera en
          vigueur à la publication de l’avis de mise à jour. Si la loi l’exige,
          Stryker pourrait demander votre consentement à toute modification
          importante apportée à la présente déclaration de confidentialité.
        </div>
        <div
          class="title-color-privacy"
          :style="fontStyles.fontFamilyFuturaVerySmall"
        >
          Contact
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          Si vous avez des questions, des préoccupations ou des commentaires
          concernant cette déclaration, veuillez nous contacter. Stryker
          déploiera des efforts raisonnables pour vous répondre aussitôt que
          possible.
        </div>
        <div :style="fontStyles.fontFamilyFuturaVerySmall">
          Écrivez-nous à l’adresse suivante :
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div>Stryker Corporation</div>
          <div>Attn. : Data Privacy Director</div>
          <div>2825 Airview Boulevard</div>
          <div>Kalamazoo, MI 49002</div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaVerySmall">
          Envoyez-nous un e-mail à l’adresse suivante :
        </div>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div>
            <a href="mailto:globalprivacy@stryker.com"
              >globalprivacy@stryker.com</a
            >
          </div>
          <div>
            Délégué à la protection des données de l'UE :
            <a href="mailto:europe.privacy@stryker.com"
              >europe.privacy@stryker.com</a
            >
          </div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaButton">
          <div>
            Si nous ne vous répondons pas dans un délai raisonnable après
            l’envoi de votre demande par écrit ou si notre réponse ne vous
            satisfait pas, vous pouvez porter plainte auprès de l’instance
            chargée de la protection des données dans votre pays de résidence.
          </div>
          <div>Dernières mises à jour février/2025</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";
export default {
  name: "PrivacyView",
  mixins: [fontMixin],
  components: {},
  data() {
    return {
      //
    };
  }
};
</script>

<style>
.title-color-privacy {
  color: #ffb500;
}
</style>
