<template>
  <v-container class="login-container" fluid>
    <div class="background-overlay"></div>
    <v-card
      width="900"
      class="login-card"
      color="#FFFFFF"
      style="border-radius: 0px"
    >
      <v-card-title :style="fontStyles.fontFamilyFutura">
        {{ $t("enter-password") }}
      </v-card-title>
      <v-form
        ref="form"
        @submit.prevent="submit"
        @keydown.enter.prevent="submit"
      >
        <v-card-text class="pb-0">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            rounded="0"
            :append-inner-icon="showPwd ? mdiEye : mdiEyeOff"
            :type="showPwd ? 'text' : 'password'"
            @click:append-inner="showPwd = !showPwd"
            :counter="60"
            required
            variant="outlined"
            :label="this.$t('password')"
            :style="fontStyles.fontFamilyEgyptienne"
          >
            <template v-slot:prepend-inner>
              <v-icon :icon="mdiKeyOutline" size="large" class="mr-3"></v-icon>
              <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            </template>
          </v-text-field>

          <v-text-field
            v-model="passwordRepeat"
            :rules="passwordRules"
            rounded="0"
            :append-inner-icon="showPwd ? mdiEye : mdiEyeOff"
            :type="showPwd ? 'text' : 'password'"
            @click:append-inner="showPwd = !showPwd"
            :counter="60"
            required
            variant="outlined"
            :label="this.$t('confirm-password')"
            :style="fontStyles.fontFamilyEgyptienne"
          >
            <template v-slot:prepend-inner>
              <v-icon :icon="mdiKeyOutline" size="large" class="mr-3"></v-icon>
              <v-divider class="border-opacity-50 my-3" vertical></v-divider>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-actions class="card-actions-component">
          <div class="card-action-container">
            <div class="login-button-action-container">
              <v-spacer></v-spacer>
              <v-btn
                rounded="0"
                class="me-4 mb-2 text-white"
                color="#395d5b"
                @click="submit"
                @keydown.enter.prevent="submit"
                :loading="loading"
                variant="elevated"
                :prepend-icon="mdiLogin"
                :style="fontStyles.fontFamilyFuturaButton"
              >
                {{ this.$t("save") }}
              </v-btn>
            </div>
            <v-divider></v-divider>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLogin,
  mdiKeyOutline,
  mdiEye,
  mdiEyeOff
} from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "CreateOrResetPasswordView",
  mixins: [fontMixin],
  props: {
    userId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mdiAccountOutline,
      mdiLogin,
      mdiEye,
      mdiEyeOff,
      mdiKeyOutline,
      showPwd: false,
      valid: false,
      loading: false,
      password: "",
      passwordRepeat: "",
      passwordRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        },
        (value) => {
          if (value.length >= 8) return true;
          return this.$t("rules.password-8");
        },
        (value) => {
          if (/[a-z]/.test(value)) return true;
          return this.$t("rules.password-lowercase");
        },
        (value) => {
          if (/[A-Z]/.test(value)) return true;
          return this.$t("rules.password-uppercase");
        },
        (value) => {
          if (/\d/.test(value)) return true;
          return this.$t("rules.password-digit");
        },
        (value) => {
          if (/[^a-zA-Z0-9]/.test(value)) return true;
          return this.$t("rules.password-nonalphanumeric");
        }
      ]
    };
  },
  methods: {
    async submit() {
      // this.loading = true;
      this.loading = true;
      const { valid } = await this.$refs.form.validate();
      if (this.password !== this.passwordRepeat) {
        this.$store.dispatch("showSnackbar", {
          message: this.$t("rules.password-confirmation"),
          color: "error"
        });
        this.loading = false;
        return;
      }
      try {
        if (valid) {
          this.loading = false;
          this.$store
            .dispatch("auth/resetPassword", {
              userId: this.userId,
              token: this.token,
              password: this.password,
              confirmPassword: this.passwordRepeat
            })
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("success.password-reset"),
                color: "success"
              });
              this.$router.push({ name: "login" });
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("errors.password-reset"),
                color: "error"
              });
            });
        }
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("showSnackbar", {
          message: this.$t("errors.password-reset"),
          color: "error"
        });
      }
    }
  }
};
</script>

<style>
.microsoft-auth-btn {
  margin-top: 10px;
}
.card-actions-component {
  display: contents;
}

.login-button-action-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.microsoft-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.microsoft-svg {
  width: 25px;
  height: 25px;
}

.login-btns {
  border-left: 4px solid #feb100;
  border-top-left-radius: 6px; /* Round the top-left corner */
  border-bottom-left-radius: 6px; /* Round the bottom-left corner */
  margin-bottom: 10px;
}

.login-container {
  height: 100%;
  display: flex; /* Active Flexbox */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  position: relative; /* Nécessaire pour le positionnement absolu de l'overlay */
}

.background-overlay {
  background-image: url("../assets/login_background.jpg");
  background-size: cover;
  background-position: center; /* Centre l'image */
  filter: blur(10px) grayscale(100%);
  -webkit-filter: blur(10px) grayscale(100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; /* Couleur blanche */
  opacity: 0.5; /* Ajuste l'opacité ici */
  z-index: 1; /* Assure que l'overlay est au-dessus de l'image mais en dessous du contenu */
  pointer-events: none; /* Permet aux clics de passer à travers ce calque */
}

.color-button {
  background: -webkit-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions de Chrome et Safari */
  background: -moz-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour Firefox */
  background: -o-linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les anciennes versions d'Opera */
  background: linear-gradient(
    to left,
    #af6d04,
    #ffb500
  ); /* Pour les navigateurs modernes */
}

.login-card {
  z-index: 2;
  margin-top: -200px; /* Default for large screens */
}

@media (min-width: 1200px) {
  .login-card {
    margin-top: -200px; /* Large screens */
  }
}

@media (max-height: 810px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  /* .v-footer {
    max-height: 150px;
  } */
}

@media (min-width: 768px) and (max-width: 1199px) {
  .login-card {
    margin-top: 0px; /* Medium screens */
  }

  /* .v-footer {
    max-height: 150px;
  } */
}

@media (max-width: 767px) {
  .login-card {
    margin-top: -50px; /* Small screens */
  }
}

@media (max-width: 480px) {
  .login-card {
    margin-top: 0; /* Extra small screens */
  }
}
</style>
