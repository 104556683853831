<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :label="$t('name') + ' *'"
                v-model="editedItem.name"
                :rules="globalRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import { mapState } from "vuex";

export default {
  name: "AddEditGimSubBrand",
  props: ["modelDialog"],
  data() {
    return {
      mdiPencil,
      mdiAccount,
      globalRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.gimSubBrands.editedId
    }),
    editedItem: {
      get() {
        return this.$store.state.gimSubBrands.editedItem;
      },
      set(value) {
        this.$store.commit("gimSubBrands/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-gim-sub-brand-line")
        : this.$t("admin.edit-gim-sub-brand-line");
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        if (this.editedId === -1) {
          this.$store
            .dispatch("gimSubBrands/saveEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.gim-sub-brand-line-create"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.gim-sub-brand-line-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch("gimSubBrands/updateEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.gim-sub-brand-line-update"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.gim-sub-brand-line-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
