<template>
  <v-dialog v-model="dialog" max-width="1400">
    <v-card :prepend-icon="mdiFormatListNumbered" title="Listes de prix client">
      <v-card-text>
        <v-data-table
          :items="priceLists"
          :headers="headers"
          item-value="id"
          show-expand
          single-expand
          hide-default-footer
          v-model:expanded="expanded"
          :no-data-text="$t('no-data')"
          :style="fontStyles.fontFamilyHumanist"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:no-data>
            <import-file-dialog
              :table="'price-list'"
              @updated="close"
            ></import-file-dialog>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <import-file-dialog
              :table="'price-list'"
              @updated="close"
              :icon="item.id"
            ></import-file-dialog>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.data-table-expand="{ item }">
            <v-icon
              @click="toggleExpand(item.id)"
              :loading="loadingDetails && selectedPriceList === item.id"
            >
              {{ expanded.includes(item.id) ? mdiChevronUp : mdiChevronDown }}
            </v-icon>
          </template>

          <!-- estlint-disable-next-line -->
          <template v-slot:expanded-row="{ columns, item }">
            <tr v-if="expanded.includes(item.id)">
              <td :colspan="columns.length">
                <v-sheet elevation="9" class="mt-3 mb-3">
                  <v-table>
                    <thead>
                      <tr class="grey lighten-4">
                        <th>{{ $t("reference") }}</th>
                        <th>{{ $t("description") }}</th>
                        <th>{{ $t("price") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(product, index) in details"
                        :key="index"
                        class="grey lighten-4"
                      >
                        <td>
                          {{ product.reference }}
                        </td>
                        <td>
                          {{ product.description }}
                        </td>
                        <td>
                          <v-text-field
                            density="compact"
                            v-model="product.price"
                            variant="outlined"
                            hide-details
                            :class="{ 'green-background': product.isGreen }"
                            @keydown.enter="updateModelValue(product)"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-sheet>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :text="$t('close')"
          variant="plain"
          @click="$emit('close')"
        ></v-btn>

        <v-btn
          color="#ffb500"
          :text="$t('save')"
          variant="tonal"
          @click="save"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  mdiPlus,
  mdiFormatListNumbered,
  mdiChevronDown,
  mdiChevronUp,
  mdiFileImport
} from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";
import ImportFileDialog from "../ImportFileDialog.vue";

export default {
  name: "AddEditPriceList",
  mixins: [fontMixin],
  props: ["modelDialog"],
  components: {
    ImportFileDialog
  },
  data() {
    return {
      loadingDetails: false,
      selectedPriceList: null, // Stocke l'ID en attente d'expansion
      mdiPlus,
      mdiFormatListNumbered,
      mdiChevronDown,
      mdiChevronUp,
      mdiFileImport,
      isGreen: false,
      expanded: [],
      headers: [
        {
          title: this.$t("name"),
          align: "start",
          sortable: false,
          key: "name"
        },
        { title: this.$t("start-date"), key: "startDate" },
        { title: this.$t("end-date"), key: "endDate" },
        {
          title: this.$t("actions"),
          align: "center",
          key: "actions",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState({
      priceLists: (state) => state.priceLists.items,
      priceListProducts: (state) => state.priceLists.priceListProducts
    }),
    details: {
      get() {
        return this.$store.state.priceLists.priceListProducts;
      }
    },
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    editedItem: {
      get() {
        return this.$store.state.priceLists.editedItem;
      },
      set(value) {
        this.$store.commit("priceLists/setEditedItem", value);
      }
    }
  },
  watch: {
    selectedPriceList(newPriceList) {
      if (newPriceList !== null) {
        this.getDetails(newPriceList);
      }
    }
  },
  methods: {
    updateModelValue(product) {
      this.$store.commit("setOverlay", true);
      this.$store
        .dispatch("priceLists/updatePriceListProduct", product)
        .then(() => {
          this.$store.commit("setOverlay", false);
          product.isGreen = true;
          setTimeout(() => {
            product.isGreen = false;
          }, 2000);
        })
        .catch(() => {
          this.$store.commit("setOverlay", false);
          this.$store.dispatch("snackbar/show", {
            message: this.$t("errors.updating-price-list-product"),
            color: "error"
          });
        });
    },
    addPriceList() {},
    getDetails(priceList) {
      if (!priceList) return;
      this.loadingDetails = true;
      this.$store
        .dispatch("priceLists/getPriceListProductByPriceListId", priceList)
        .then(() => {
          this.$store.commit("priceLists/setEditedId", priceList);
          this.expanded = [priceList];
          this.selectedPriceList = null; // Développe uniquement après l'appel API
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },
    toggleExpand(priceList) {
      if (this.loadingDetails) return;

      if (this.expanded.includes(priceList)) {
        this.expanded = [];
        // this.$store.commit("priceLists/setPriceListProducts", []);
      } else {
        this.expanded = [priceList]; // Développe immédiatement
        this.selectedPriceList = priceList; // Déclenche l'appel API
      }
    },
    close() {
      this.$emit("close");
    },
    save() {
      console.log("save");
    }
  }
};
</script>

<style>
.green-background {
  background-color: green !important;
  transition: background-color 0.5s ease;
}
</style>
