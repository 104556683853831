// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { VDateInput } from "vuetify/labs/VDateInput";

// Vuetify
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { fr, en } from "vuetify/locale";

export default createVuetify({
  components: {
    VDateInput
  },
  locale: {
    current: "fr", // Définit la langue par défaut
    messages: {
      fr: fr, // Traductions pour le français
      en: en // Traductions pour l'anglais
    }
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi
    }
  }
});
