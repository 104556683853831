<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-card-title :style="fontStyles.fontFamilyFutura" class="pb-5">
        <div class="pb-2">Accessibilité au site Web</div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Stryker s'engage à fournir des sites Web, des applications mobiles
            et d'autres contenus numériques accessibles à tous ses clients, y
            compris les personnes souffrant de handicap.
          </div>
          <div>
            Pour nous faire part de questions, de commentaires ou d'éventuelles
            difficultés rencontrées sur ce site, envoyez un e-mail à l'adresse
            <a href="mailto:websiteinquiries@stryker.com">
              websiteinquiries@stryker.com
            </a>
            .
          </div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaButton">
          <div>Dernières mises à jour février/2025</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";
export default {
  name: "AccessibilityView",
  mixins: [fontMixin]
};
</script>

<style>
.title-color-privacy {
  color: #ffb500;
}
</style>
