import axios from "axios";

export default {
  get() {
    return axios.get("/api/users");
  },
  getStrykerUsers() {
    return axios.get("/api/users/StrykerEmployees");
  },
  currentUser() {
    return axios.get("/api/users/user");
  },
  store(user) {
    return axios.post("/api/users", user);
  },
  delete(id) {
    return axios.delete(`/api/users/${id}`);
  },
  update(id, user) {
    return axios.put(`/api/users/${id}`, user);
  },
  getUserInfosWithCustomers() {
    return axios.get("/api/users/user-infos-with-customers");
  }
};
