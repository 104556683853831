import priceLists from "../../api/priceLists";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    currentList: null,
    priceListProducts: [],
    currentContract: null,
    editedItem: {
      Id: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear() + 1, 0, 15), // 14th February of next year
      fK_Customer: null
    },
    defaultItem: {
      Id: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear() + 1, 0, 15),
      fK_Customer: null
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        Id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        fK_Customer: item.fK_Customer
      };
      state.editedItem = editedItem;
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setCurrentList(state, id) {
      state.currentList = id;
    },
    setCustomer(state, customer) {
      state.editedItem.fK_Customer = customer;
    },
    setCustomerName(state, name) {
      state.editedItem.name = name;
    },
    setPriceListProducts(state, products) {
      state.priceListProducts = products;
    },
    setCurrentContract(state, contract) {
      state.currentContract = contract;
    }
  },
  actions: {
    fetchAll({ commit }, data) {
      return new Promise((resolve, reject) => {
        priceLists
          .get(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchItemByCustomerId({ commit }, data) {
      return new Promise((resolve, reject) => {
        priceLists
          .getPriceListByCustomerId(data)
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getPriceListProductByPriceListId({ commit }, id) {
      return new Promise((resolve, reject) => {
        priceLists
          .getPriceListProductByPriceListId(id)
          .then((response) => {
            commit("setPriceListProducts", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updatePriceListProduct({ dispatch, state }, data) {
      return new Promise((resolve, reject) => {
        priceLists
          .updateProduct(data.id, data.price)
          .then((response) => {
            dispatch("getPriceListProductByPriceListId", state.editedId);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ commit, state }) {
      return new Promise((resolve, reject) => {
        const startDate = new Date(state.editedItem.startDate)
          .toISOString()
          .split("T")[0];
        const endDate = new Date(state.editedItem.endDate)
          .toISOString()
          .split("T")[0];
        const data = {
          fK_Contract: state.currentContract,
          startDate: startDate, // Formate la date au format YYYY-MM-DD
          endDate: endDate, // Formate la date au format YYYY-MM-DD
          name: state.editedItem.name + "_" + startDate + "_" + endDate
        };
        priceLists
          .store(data)
          .then((response) => {
            commit("setCurrentList", response.data.id);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        priceLists
          .update(state.editedId, state.editedItem)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        priceLists
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
