import axios from "axios";

export default {
  get() {
    return axios.get("/api/contracts");
  },
  store(data) {
    return axios.post("/api/contracts", data);
  },
  getByCustomerId(customerId) {
    return axios.get(`/api/contracts/customer/${customerId}`);
  },
  getExclusions(customerId) {
    return axios.get("/api/contracts/exclusions", {
      params: {
        customerId: customerId
      }
    });
  },
  getExclusionsByContractId(contractId) {
    return axios.get(`/api/contracts/${contractId}/exclusions`);
  },
  putExclusions(data) {
    return axios.put("/api/contracts/exclusions", data);
  },
  update(id, data) {
    return axios.put(`/api/contracts/${id}`, data);
  },
  delete(id) {
    return axios.delete(`/api/contracts/${id}`);
  }
};
