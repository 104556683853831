<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiFileDocument" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                :label="$t('name') + ' *'"
                v-model="editedItem.name"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-switch
                v-model="editedItem.active"
                :label="$t('active')"
                color="#ffb500"
                hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-locale-provider locale="fr">
                <v-date-input
                  :label="$t('start-date') + ' *'"
                  v-model="startDate"
                  max-width="368"
                ></v-date-input>
              </v-locale-provider>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-locale-provider locale="fr">
                <v-date-input
                  :label="$t('end-date') + ' *'"
                  v-model="endDate"
                  max-width="368"
                ></v-date-input>
              </v-locale-provider>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                :label="$t('admin.customers')"
                v-model="editedItem.customerNumbers"
                :items="customers"
                @update:search="debouncedUpdateCustomers"
                item-title="number"
                item-value="number"
                closable-chips
                chips
                multiple
                show-select
                variant="outlined"
              ></v-autocomplete>
            </v-col>

            <v-divider class="pb-3"></v-divider>
            <v-col cols="12" md="12" sm="12" class="pb-3">
              <h4>Exceptions :</h4>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-autocomplete
                :label="$t('admin.gim-sub-brand')"
                v-model="editedItemExclusions.gimSubBrands"
                :items="gimSubBrands"
                @update:search="debouncedUpdateGimSubBrands"
                item-title="name"
                return-object
                multiple
                closable-chips
                chips
                show-select
                variant="outlined"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-autocomplete
                :label="$t('admin.franchise')"
                v-model="editedItemExclusions.franchises"
                :items="franchises"
                @update:search="debouncedUpdateFranchises"
                item-title="name"
                return-object
                multiple
                closable-chips
                chips
                show-select
                variant="outlined"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiFileDocument } from "@mdi/js";
import paginations from "@/mixins/paginations";
import { mapState } from "vuex";
import _ from "lodash"; // Importer lodash

export default {
  name: "AddEditContract",
  props: ["modelDialog"],
  mixins: [paginations],
  data() {
    return {
      mdiFileDocument,
      nameRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ],
      customerRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ],
      dateRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ]
    };
  },
  created() {
    // Déclarer une méthode débouncée
    this.debouncedUpdateCustomers = _.debounce(this.updateCustomers, 300);
    this.debouncedUpdateGimSubBrands = _.debounce(this.updateGimSubBrands, 300);
    this.debouncedUpdateFranchises = _.debounce(this.updateFranchises, 300);
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.contracts.editedId,
      customers: (state) => state.clients.secondItemsList,
      gimSubBrands: (state) => state.gimSubBrands.items,
      franchises: (state) => state.franchises.items
    }),
    startDate: {
      get() {
        return new Date(this.$store.state.contracts.editedItem.startDate);
      },
      set(value) {
        this.$store.commit("contracts/setEditedStartDate", value);
      }
    },
    endDate: {
      get() {
        return new Date(this.$store.state.contracts.editedItem.endDate);
      },
      set(value) {
        this.$store.commit("contracts/setEditedEndDate", value);
      }
    },
    editedItemExclusions: {
      get() {
        return this.$store.state.contracts.editedItemExclusions;
      },
      set(value) {
        this.$store.commit("contracts/setEditedItemExclusions", value);
      }
    },
    editedItem: {
      get() {
        return this.$store.state.contracts.editedItem;
      },
      set(value) {
        this.$store.commit("contracts/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-contract")
        : this.$t("admin.edit-contract");
    }
  },
  methods: {
    updateCustomers(item) {
      this.searchBackSecondStore(item, 500, "clients", false);
    },
    updateGimSubBrands(item) {
      this.searchBack(item, 500, "gimSubBrands", false);
    },
    updateFranchises(item) {
      this.searchBack(item, 500, "franchises", false);
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        if (this.editedId === -1) {
          this.$store
            .dispatch("contracts/saveEditedItem")
            .then((response) => {
              if (
                !this.editedItemExclusions.gimSubBrands == 0 ||
                !this.editedItemExclusions.franchises == 0
              ) {
                const data = {
                  fK_ContractId: response.data.id,
                  gimSubBrands: this.editedItemExclusions.gimSubBrands,
                  franchises: this.editedItemExclusions.franchises
                };
                this.$store
                  .dispatch("contracts/putExclusions", data)
                  .then(() => {
                    this.$store.dispatch("showSnackbar", {
                      message: this.$t("alert.success.contract-update"),
                      color: "success"
                    });
                    this.close();
                  })
                  .catch(() => {
                    this.$store.dispatch("showSnackbar", {
                      message: this.$t("alert.error.exlusion-update"),
                      color: "error"
                    });
                    this.close();
                  });
              } else {
                this.$store.dispatch("showSnackbar", {
                  message: this.$t("alert.success.contract-update"),
                  color: "success"
                });
                this.close();
              }
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.contract-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch("contracts/updateEditedItem")
            .then(() => {
              this.$store
                .dispatch("contracts/putExclusions", this.editedItemExclusions)
                .then(() => {
                  this.$store.dispatch("showSnackbar", {
                    message: this.$t("alert.success.contract-update"),
                    color: "success"
                  });
                  this.close();
                })
                .catch(() => {
                  this.$store.dispatch("showSnackbar", {
                    message: this.$t("alert.error.exlusion-update"),
                    color: "error"
                  });
                  this.close();
                });
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.contract-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
