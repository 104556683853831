<template>
  <v-dialog v-model="dialog" max-width="900">
    <v-card
      :prepend-icon="mdiAccount"
      :title="formTitle"
      style="border-radius: 0px"
    >
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row class="d-flex flex-row">
            <v-col
              cols="4"
              class="d-flex align-center justify-center flex-wrap text-center"
            >
              <v-sheet
                elevation="8"
                class="d-flex align-center justify-center flex-wrap text-center"
                height="200"
                width="250"
              >
                <div>
                  <h2 class="text-h2 font-weight-black text-color">
                    {{
                      this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                        ? 0
                        : Math.ceil(this.stats.totalPoints).toLocaleString(
                            "fr-FR"
                          )
                    }}
                  </h2>

                  <div class="text-body-2 mt-2">
                    {{
                      this.$t("total-pts-year", {
                        year: new Date().getFullYear()
                      })
                    }}
                  </div>
                </div>
              </v-sheet>
              <!-- <v-btn :text="$t('close')" variant="plain" @click="test"></v-btn> -->
            </v-col>
            <v-col cols="8">
              <v-text-field
                :label="$t('admin.enter-points')"
                variant="solo-filled"
                v-model="points"
                :rules="[
                  (v) => !!v || $t('admin.points-must-be-number'),
                  (v) => /^-?\d+$/.test(v) || $t('admin.points-must-be-number')
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="savePointsUpdate"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mdiAccount } from "@mdi/js";
import points from "@/mixins/points";

export default {
  name: "AddOrRemoveCustomerPointsDialog",
  mixins: [points],
  props: ["modelDialog"],
  data() {
    return {
      mdiAccount,
      formTitle: this.$t("admin.add-edit-customer-points"),
      points: 0
    };
  },
  computed: {
    ...mapState({
      editedId: (state) => state.clients.editedId,
      stats: (state) => state.statsCustomer.statsCustomer
    }),
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    savePointsUpdate() {
      const valid = this.points !== 0 && /^-?\d+$/.test(this.points);
      if (valid) {
        const data = {
          customerId: this.editedId,
          manualPointsToAdd: this.points
        };
        this.$store
          .dispatch("statsCustomer/updatePoints", data)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              text: this.$t("alert.success.add-edit-customer-points"),
              color: "success"
            });
            // this.$emit("close");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              text: this.$t("alert.error.add-edit-customer-points"),
              color: "error"
            });
          });
      } else {
        this.$store.dispatch("showSnackbar", {
          text: this.$t("admin.points-must-be-number"),
          color: "error"
        });
      }
    }
  }
};
</script>

<style>
.text-color {
  color: #feb100;
}
</style>
