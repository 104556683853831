export default {
  data() {
    return {
      fontStyles: {
        fontFamilyFutura: {
          fontFamily: "Futura LT W01, Arial Black, Arial, sans-serif",
          fontWeight: "700",
          fontSize: "28px",
          lineHeight: "1.06"
        },
        fontFamilyFuturaSubTitle: {
          fontFamily: "Futura LT W01, Arial Black, Arial, sans-serif",
          fontWeight: "700",
          fontSize: "21px",
          lineHeight: "1.06"
        },
        fontFamilyFuturaVerySmall: {
          fontFamily: "Futura LT W01, Arial Black, Arial, sans-serif",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "1.06"
        },
        fontFamilyFuturaButton: {
          fontFamily: "Futura LT W01, Arial, Helvetica, sans-serif",
          fontWeight: "400"
        },
        fontFamilyHumanist: {
          fontFamily: "HumanistSlab712W01, Cambria, Palatino, serif",
          fontWeight: "400"
        },
        fontFamilyHumanistBold: {
          fontFamily: "HumanistSlab712W01, Cambria, Palatino, serif",
          fontWeight: "700"
        },
        fontFamilyEgyptienne: {
          fontFamily: "URWEgyptienneW01-Light, Rockwell, Georgia, Times, serif",
          fontWeight: "400",
          fontSize: "21px"
        },
        fontFamilyEgyptienneSubTitle: {
          fontFamily: "URWEgyptienneW01-Light, Rockwell, Georgia, Times, serif",
          fontWeight: "400",
          fontSize: "17px"
        }
      }
    };
  }
};
