<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="strykerCommands"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <import-file-dialog :table="'strykerCommands'"></import-file-dialog>
          <v-tooltip
            location="top"
            text="Recalculer les colonnes personnalisées"
          >
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                class="mb-2 mr-2"
                color="#ffb500"
                size="large"
                @click="recalculateColumns"
              >
                {{ mdiRefresh }}
              </v-icon>
            </template>
          </v-tooltip>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.customerReported="{ item }">
        {{ item.customerReported.number }} -
        {{ item.customerReported.name }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.customerSoldTo="{ item }">
        <span v-if="item.customerSoldTo">
          {{ item.customerSoldTo.number }} -
          {{ item.customerSoldTo.name }}
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'strykerCommands'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-stryker-command
      :modelDialog="strykerCommandDialog"
      @close="strykerCommandDialog = false"
    ></add-edit-stryker-command>
  </v-container>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiAccountPlus,
  mdiRefresh
} from "@mdi/js";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import ImportFileDialog from "./ImportFileDialog.vue";
import AddEditStrykerCommand from "./admin/AddEditStrykerCommand.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "strykerCommandAdmin",
  components: {
    ImportFileDialog,
    PaginationDynamic,
    AddEditStrykerCommand
  },
  mixins: [paginate],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiAccountPlus,
      mdiRefresh,
      dialog: false,
      dialogDelete: false,
      strykerCommandDialog: false,
      headers: [
        {
          title: this.$t("admin.customer-sold-to"),
          align: "start",
          sortable: false,
          key: "customerSoldTo"
        },
        {
          title: this.$t("admin.customer-reported"),
          key: "customerReported"
        },
        { title: this.$t("admin.invoice-number"), key: "invoiceNumber" },
        { title: this.$t("order-number"), key: "orderNumber" },
        { title: this.$t("admin.reference"), key: "reference" },
        { title: this.$t("admin.description"), key: "description" },
        { title: this.$t("actions"), key: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      strykerCommands: (state) => state.strykerCommands.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },

  watch: {},

  methods: {
    recalculateColumns() {
      window.confirm(
        "Voulez-vous vraiment recalculer les colonnes personnalisées ?"
      ) &&
        this.$store
          .dispatch("strykerCommands/recalculateColumns")
          .then((response) => {
            this.$store.dispatch("showSnackbar", {
              message: response.data,
              color: "success"
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toSort(item) {
      this.sortBy(item, "strykerCommands");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "strykerCommands");
    },
    editItem(item) {
      this.$store.commit("strykerCommands/setEditedId", item.id);
      this.$store.commit(
        "strykerCommands/setEditedItem",
        Object.assign({}, item)
      );
      this.strykerCommandDialog = true;
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
