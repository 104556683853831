import axios from "axios";

export default {
  getByCustomer(customerId) {
    return axios.get(`/api/carts/${customerId}`);
  },
  getItemsCart(itemId) {
    return axios.get(`/api/carts/items/${itemId}`);
  },
  postItemsCart(item) {
    return axios.post(`/api/carts/items/${item.customer}`, item);
  },
  putItemsCart(itemId, item) {
    return axios.put(`/api/carts/items/${itemId}`, item);
  },
  deleteItemsCart(itemId) {
    return axios.delete(`/api/carts/items/${itemId}`);
  },
  postDiscountCart(data) {
    return axios.put(
      `/api/carts/${data.customerId}/discountRate`,
      data.discount,
      {
        headers: { "Content-Type": "application/json" }
      }
    );
  }
};
