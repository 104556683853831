export default {
  name: "points",
  data() {
    return {
      //
    };
  },
  methods: {
    isDivisibleBy(value, divisor) {
      return Math.floor(value / divisor) > 0;
    }
  }
};
