import contracts from "../../api/contracts";

export default {
  namespaced: true,
  state: {
    items: [],
    editedId: -1,
    loading: false,
    editedItemExclusions: [],
    editedItem: {
      Id: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear() + 1, 0, 15),
      customerNumbers: [],
      active: false
    },
    defaultItem: {
      Id: null,
      name: "",
      startDate: new Date(),
      endDate: new Date(new Date().getFullYear() + 1, 0, 15),
      customerNumbers: [],
      active: false
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      const editedItem = {
        Id: item.id,
        name: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
        customerNumbers: item.customerNumbers,
        active: item.active
      };
      state.editedItem = editedItem;
    },
    setEditedItemExclusions(state, item) {
      state.editedItemExclusions = item;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setEditedEndDate(state, endDate) {
      state.editedItem.endDate = endDate;
    },
    setEditedStartDate(state, startDate) {
      state.editedItem.startDate = startDate;
    }
  },
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        contracts
          .get()
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getByCustomerId({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        contracts
          .getByCustomerId(customerId)
          .then((response) => {
            commit("setLoading", false);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getExclusions({ commit }, customerId) {
      return new Promise((resolve, reject) => {
        contracts
          .getExclusions(customerId)
          .then((response) => {
            commit("setEditedItemExclusions", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getExclusionsByContractId({ commit }, contractId) {
      return new Promise((resolve, reject) => {
        contracts
          .getExclusionsByContractId(contractId)
          .then((response) => {
            commit("setEditedItemExclusions", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    putExclusions({ commit }, data) {
      return new Promise((resolve, reject) => {
        contracts
          .putExclusions(data)
          .then((response) => {
            commit("setLoading", false);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const formatDate = (date) => {
          if (typeof date === "string" && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return date;
          }
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const formattedDateStart = formatDate(state.editedItem.startDate);
        const formattedDateEnd = formatDate(state.editedItem.endDate);

        const data = {
          name: state.editedItem.name,
          startDate: formattedDateStart,
          endDate: formattedDateEnd,
          customerNumbers: state.editedItem.customerNumbers,
          active: state.editedItem.active
        };
        contracts
          .store(data)
          .then((response) => {
            // commit("setItems", response.data);
            dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        const formatDate = (date) => {
          if (typeof date === "string" && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return date;
          }
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${year}-${month}-${day}`;
        };

        const formattedDateStart = formatDate(state.editedItem.startDate);
        const formattedDateEnd = formatDate(state.editedItem.endDate);

        const data = {
          name: state.editedItem.name,
          startDate: formattedDateStart,
          endDate: formattedDateEnd,
          customerNumbers: state.editedItem.customerNumbers,
          active: state.editedItem.active
        };
        contracts
          .update(state.editedId, data)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        contracts
          .delete(id)
          .then((response) => {
            dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  getters: {}
};
