<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :prepend-icon="mdiAccount" :title="formTitle">
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('address1')"
                v-model="editedItem.addressLine1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('address2')"
                v-model="editedItem.addressLine2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('city')"
                v-model="editedItem.city"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('state')"
                v-model="editedItem.state"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('zip-code')"
                v-model="editedItem.zipCode"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('country')"
                v-model="editedItem.country"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6" v-if="!this.customer">
              <v-autocomplete
                :label="$t('admin.customer') + ' *'"
                v-model="editedItem.fK_Customer"
                :items="customers"
                @update:search="debouncedUpdateCustomers"
                item-title="number"
                item-value="id"
                :rules="customerRules"
                required
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('address-name')"
                v-model="editedItem.AddressName"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                :label="$t('full-address') + ' *'"
                v-model="editedItem.fullAddress"
                :rules="fullAddressRules"
                required
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="4" sm="6">
              <v-text-field
                :label="$t('address-type')"
                v-model="editedItem.addressType"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="4" sm="6" v-if="!this.customer">
              <v-switch
                v-model="editedItem.addressType"
                :label="$t('delivery-address')"
                color="#ffb500"
                false-value="0"
                true-value="1"
                hide-details
              ></v-switch>
            </v-col>

            <v-col cols="12" md="4" sm="6" v-if="!this.customer">
              <v-switch
                v-model="editedItem.addressType"
                :label="$t('billing-address')"
                color="#ffb500"
                false-value="1"
                true-value="0"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
          <small class="text-caption text-medium-emphasis">
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="$emit('close')"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('save')"
            variant="tonal"
            @click="save"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil, mdiAccount } from "@mdi/js";
import paginations from "@/mixins/paginations";
import { mapState } from "vuex";
import _ from "lodash"; // Importer lodash

export default {
  name: "AddEditAddress",
  mixins: [paginations],
  props: {
    modelDialog: Boolean,
    customer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mdiPencil,
      mdiAccount,
      fullAddressRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ],
      customerRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        }
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    ...mapState({
      editedId: (state) => state.addresses.editedId,
      customers: (state) => state.clients.items
    }),
    editedItem: {
      get() {
        return this.$store.state.addresses.editedItem;
      },
      set(value) {
        this.$store.commit("addresses/setEditedItem", value);
      }
    },
    formTitle() {
      return this.editedId === -1
        ? this.$t("admin.new-address")
        : this.$t("admin.edit-address");
    }
  },
  created() {
    // Déclarer une méthode débouncée
    this.debouncedUpdateCustomers = _.debounce(this.updateCustomers, 300);
  },
  methods: {
    updateCustomers(item) {
      this.searchBack(item, 500, "clients", false);
    },
    close() {
      this.$emit("close");
    },
    save() {
      const valid = this.$refs.form.isValid;
      if (valid) {
        if (this.editedId === -1) {
          this.$store
            .dispatch("addresses/saveEditedItem")
            .then((response) => {
              if (this.customer) {
                this.$store.dispatch(
                  "addresses/fetchItemByCustomerId",
                  response.customer
                );
              }
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.address-create"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.address-create"),
                color: "error"
              });
              this.close();
            });
        } else {
          this.$store
            .dispatch("addresses/updateEditedItem")
            .then(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.success.address-update"),
                color: "success"
              });
              this.close();
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.address-update"),
                color: "error"
              });
              this.close();
            });
        }
      }
    }
  }
};
</script>
