<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card
      :prepend-icon="mdiLock"
      :style="fontStyles.fontFamilyFutura"
      :title="$t('reset-password')"
    >
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                :style="fontStyles.fontFamilyEgyptienne"
                :label="$t('email') + '*'"
                :rules="emailRules"
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>

          <div :style="fontStyles.fontFamilyFuturaVerySmall" class="mb-3">
            {{ $t("link-reset-password") }}
          </div>
          <small
            :style="fontStyles.fontFamilyFuturaButton"
            class="text-caption text-medium-emphasis"
          >
            {{ $t("require-fields") }}
          </small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :text="$t('close')"
            variant="plain"
            @click="close"
            :style="fontStyles.fontFamilyFuturaButton"
          ></v-btn>

          <v-btn
            color="#ffb500"
            type="submit"
            :text="$t('send')"
            variant="tonal"
            @click="submit"
            @keydown.enter.prevent="submit"
            :style="fontStyles.fontFamilyFuturaButton"
          ></v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiLock } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "AddEditAddress",
  props: ["modelDialog"],
  mixins: [fontMixin],
  data() {
    return {
      mdiLock,
      email: "",
      emailRules: [
        (value) => {
          if (value) return true;
          return this.$t("rules.required");
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;
          return this.$t("rules.email-valid");
        }
      ]
    };
  },
  computed: {
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.$store
          .dispatch("auth/askResetPassword", this.email)
          .then(() => {
            this.loading = false;
            this.$store.dispatch("showSnackbar", {
              message: this.$t("success.email-send-reset-password"),
              color: "success"
            });
            this.close();
          })
          .catch(() => {
            this.loading = false;
            this.$store.dispatch("showSnackbar", {
              message: this.$t("errors.email-send-reset-password"),
              color: "error"
            });
          });
      } else {
        this.loading = false;
        this.$store.dispatch("showSnackbar", {
          message: this.$t("rules.email-valid"),
          color: "error"
        });
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
