<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-card-title :style="fontStyles.fontFamilyFutura" class="pb-5">
        <div class="pb-2">
          Informations à l'intention des professionnels de santé
        </div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">
            Ces informations sont uniquement destinées à l’usage des
            professionnels de santé. Les professionnels de santé doivent
            toujours se baser sur leur propre jugement professionnel et clinique
            pour déterminer si un produit convient au traitement d’un patient.
            Stryker ne fournit pas de conseils médicaux et recommande aux
            professionnels de santé de suivre une formation avant d’utiliser
            tout produit particulier dans le cadre d’une procédure ou d’une
            intervention chirurgicale. Les professionnels de santé doivent
            toujours consulter la notice, l’étiquette d’identification du
            produit et/ou le manuel d’utilisation, notamment les instructions de
            nettoyage et de stérilisation (le cas échéant), avant d’utiliser un
            produit de Stryker. Les informations présentées sont destinées à
            démontrer des produits particuliers ainsi que l’étendue des produits
            proposés par Stryker. La disponibilité de certains produits peut
            varier en fonction des marchés, selon les pratiques réglementaires
            et/ou médicales en vigueur. Veuillez contacter votre représentant
            Stryker si vous souhaitez connaître la disponibilité de produits
            Stryker spécifiques dans votre région. Les produits Stryker portant
            la marque CE sont marqués conformément aux réglementations et
            directives de l’UE en vigueur.
          </div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaButton">
          <div>180423</div>
          <div>Dernières mises à jour février/2025</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";
export default {
  name: "DisclaimerView",
  mixins: [fontMixin]
};
</script>

<style>
.title-color-privacy {
  color: #ffb500;
}
</style>
