import users from "@/api/users";

export default {
  namespaced: true,
  state: {
    items: [],
    user: null,
    selectedItems: null,
    editedId: -1,
    customers: [],
    editedItem: {
      firstName: "",
      email: "",
      lastName: "",
      userRole: "",
      active: false
    },
    defaultItem: {
      firstName: "",
      email: "",
      lastName: "",
      userRole: "",
      active: false
    },
    selectedCustomer: {
      id: -1,
      logo: null,
      name: "",
      status: -1
    }
  },
  getters: {},
  actions: {
    fetchAll({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .get()
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCurrentUser({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .currentUser()
          .then((response) => {
            commit("setUser", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchStrykerUsers({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .getStrykerUsers()
          .then((response) => {
            commit("setItems", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getUserInfosWithCustomers({ commit }) {
      return new Promise((resolve, reject) => {
        users
          .getUserInfosWithCustomers()
          .then((response) => {
            commit("setUser", response.data.user);
            commit("setCustomers", response.data.customers);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    resetSelectedCustomer({ commit }) {
      commit(
        "setSelectedCustomer",
        Object.assign(
          {},
          {
            id: -1,
            logo: null,
            name: "",
            status: -1
          }
        )
      );
    },
    saveEditedItem({ dispatch, state, rootState }, roleId) {
      return new Promise((resolve, reject) => {
        var role = "";
        if (Number.isInteger(roleId)) {
          role = rootState.roles.items.find((role) => role.id === roleId).name;
        } else {
          role = roleId;
        }
        const user = {
          firstName: state.editedItem.firstName,
          lastName: state.editedItem.lastName,
          email: state.editedItem.email,
          active: state.editedItem.active,
          userRole: role
        };
        users
          .store(user)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateEditedItem({ dispatch, state, rootState }, roleId) {
      return new Promise((resolve, reject) => {
        var role = "";
        if (Number.isInteger(roleId)) {
          role = rootState.roles.items.find((role) => role.id === roleId).name;
        } else {
          role = roleId;
        }
        const user = {
          firstName: state.editedItem.firstName,
          lastName: state.editedItem.lastName,
          email: state.editedItem.email,
          active: state.editedItem.active,
          userRole: role,
          id: state.editedId
        };
        users
          .update(state.editedId, user)
          .then((response) => {
            dispatch("fetchAll");
            dispatch("resetEditedItem");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //eslint-disable-next-line
    deleteItem({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        users
          .delete(id)
          .then((response) => {
            // dispatch("fetchAll");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    resetEditedItem({ commit, state }) {
      commit("setEditedId", -1);
      commit("setEditedItem", Object.assign({}, state.defaultItem));
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setSelectedCustomer(state, customer) {
      const selectedCustomer = {
        id: customer.id,
        logo: customer.logo,
        name: customer.name,
        status: customer.status
      };
      window.localStorage.setItem("cust_stryker_one", JSON.stringify(customer));
      state.selectedCustomer = selectedCustomer;
    },
    setCustomers(state, customers) {
      state.customers = customers;
    },
    setItems(state, items) {
      state.items = items;
    },
    setEditedItem(state, item) {
      state.editedItem = { ...item };
    },
    setEditedId(state, id) {
      state.editedId = id;
    },
    setDefaultItem(state) {
      state.editedItem = { ...state.defaultItem };
    }
  }
};
