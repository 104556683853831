<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="contracts"
      :search="search"
      density="compact"
      :items-length="totalItems"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiFileDocumentPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            {{ $t("admin.new-contract") }}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.startDate="{ item }">
        {{ formatDate(item.startDate) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.endDate="{ item }">
        {{ formatDate(item.endDate) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.customerNumbers="{ item }">
        <v-chip-group v-model="item.customerNumbers" disabled>
          <v-chip
            v-for="customer in item.customerNumbers"
            :key="customer"
            :text="customer"
            size="small"
          ></v-chip>
        </v-chip-group>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="item.active ? 'green' : 'grey'"
          :text="
            item.active ? $t('active-contracts') : $t('inactive-contracts')
          "
          size="small"
        ></v-chip>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'contracts'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-contract
      :modelDialog="contractDialog"
      @close="closeContractDialog"
    ></add-edit-contract>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify, mdiFileDocumentPlus } from "@mdi/js";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import AddEditContract from "./admin/AddEditContract.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "ContractAdmin",
  mixins: [paginate],
  components: {
    AddEditContract,
    PaginationDynamic
  },
  data() {
    return {
      mdiFileDocumentPlus,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      contractDialog: false,
      itemsPerPage: 15,
      page: 1,
      headers: [
        { title: this.$t("name"), key: "name" },
        { title: this.$t("start-date"), key: "startDate" },
        { title: this.$t("end-date"), key: "endDate" },
        { title: this.$t("active-contracts"), key: "active" },
        { title: this.$t("admin.customers"), key: "customerNumbers" },
        {
          title: this.$t("actions"),
          key: "actions",
          sortable: false,
          align: "end"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      contracts: (state) => state.contracts.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.contracts.editedId
    }),
    pageCount() {
      return Math.ceil(this.contracts.length / this.itemsPerPage);
    },
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    closeContractDialog() {
      this.contractDialog = false;
      this.$store.commit("contracts/setEditedId", -1);
      this.$store.commit(
        "contracts/setEditedItem",
        Object.assign({}, this.$store.state.contracts.defaultItem)
      );
      this.$store.commit("contracts/setEditedItemExclusions", []);
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    toSort(item) {
      this.sortBy(item, "contracts");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "contracts");
    },
    openDialog() {
      this.$store
        .dispatch("fetchContractAdmin")
        .then(() => {
          this.$store.commit("contracts/setEditedId", -1);
          this.$store.commit(
            "contracts/setEditedItem",
            Object.assign({}, this.$store.state.contracts.defaultItem)
          );
          this.contractDialog = true;
        })
        .catch(() => {
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.contract-edit"),
            color: "error"
          });
        });
    },
    editItem(item) {
      this.$store
        .dispatch("contracts/getExclusionsByContractId", item.id)
        .then(() => {
          this.$store
            .dispatch("fetchContractAdmin")
            .then(() => {
              this.$store.commit("contracts/setEditedId", item.id);
              this.$store.commit(
                "contracts/setEditedItem",
                Object.assign({}, item)
              );
              this.contractDialog = true;
            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                message: this.$t("alert.error.contract-edit"),
                color: "error"
              });
            });
        })
        .catch(() => {
          this.$store.dispatch("showSnackbar", {
            message: this.$t("alert.error.contract-edit"),
            color: "error"
          });
        });
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.contract"))) {
        this.$store
          .dispatch("contracts/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.contract-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "contracts");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.contract-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>
