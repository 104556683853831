<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="termsAndConditions"
      :search="search"
      density="compact"
      :loading="loadingDatatables"
    >
      <!-- <v-data-table-server
      :headers="headers"
      :items="tersAndConditions"
      :search="search"
      density="compact"
      :items-length="totalItems"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    > -->
      <template v-slot:top>
        <v-toolbar flat>
          <!-- <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field> -->
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            {{ $t("admin.new-terms-and-conditions") }}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.fullText="{ item }">
        <span>{{ item.fullText.substring(0, 40) + "..." }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.versionDate="{ item }">
        <span>{{ new Date(item.versionDate).toLocaleDateString() }}</span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.isActive="{ item }">
        <v-icon
          :color="item.isActive ? '#ffb500' : '#b2b4ae'"
          :icon="item.isActive ? mdiCheckBold : mdiCloseThick"
          size="40"
        ></v-icon>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <!-- <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon> -->
      </template>

      <!-- <template v-slot:bottom>
        <pagination-dynamic :store="'targetProductGroups'"></pagination-dynamic>
      </template> -->
    </v-data-table-server>
    <add-edit-terms-and-conditions
      :modelDialog="termsAndConditionsDialog"
      @close="termsAndConditionsDialog = false"
    ></add-edit-terms-and-conditions>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiAccountPlus,
  mdiCheckBold,
  mdiCloseThick
} from "@mdi/js";
// import PaginationDynamic from "@/components/PaginationDynamic.vue";
// import paginate from "@/mixins/paginations";
import AddEditTermsAndConditions from "./admin/AddEditTermsAndConditions.vue";

export default {
  name: "TermsAndConditionsAdmin",
  components: { AddEditTermsAndConditions },
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiCloseThick,
      mdiCheckBold,
      mdiAccountPlus,
      termsAndConditionsDialog: false,
      headers: [
        { title: "Version", key: "versionNumber" },
        { title: "Version Date", key: "versionDate" },
        { title: "Text", key: "fullText" },
        { title: "Actif", key: "isActive" },
        { title: "Actions", key: "actions", sortable: false, align: "end" }
      ]
    };
  },
  computed: {
    ...mapState({
      termsAndConditions: (state) => state.termsAndConditions.items,
      loadingDatatables: (state) => state.loadingTables,
      editedId: (state) => state.termsAndConditions.editedId
    })
  },
  methods: {
    deleteItem(item) {
      console.log(item);
    },
    editItem(item) {
      this.$store.commit("termsAndConditions/setEditedId", item.id);
      this.$store.commit(
        "termsAndConditions/setEditedItem",
        Object.assign({}, item)
      );
      this.termsAndConditionsDialog = true;
    },
    openDialog() {
      this.$store.commit("termsAndConditions/setEditedId", -1);
      this.$store.commit(
        "termsAndConditions/setEditedItem",
        Object.assign({}, this.$store.state.termsAndConditions.defaultItem)
      );
      this.termsAndConditionsDialog = true;
    }
  }
};
</script>
