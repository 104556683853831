<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title
        class="title-background"
        :style="fontStyles.fontFamilyFuturaSubTitle"
      >
        Panier client
      </v-card-title>

      <v-card-text>
        <!-- @/assets/product.png -->

        <v-text-field
          v-model="discountRate"
          :label="$t('admin.cart-discount')"
          solo
          dense
          class="mt-2 mb-2"
          type="number"
          hide-details
        ></v-text-field>

        <v-data-table
          :headers="headers"
          :items="cart"
          density="compact"
          hide-default-footer
          item-key="name"
          :style="fontStyles.fontFamilyHumanist"
          :no-data-text="$t('no-data')"
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.image="{ item }">
            <v-img
              v-if="item.image"
              :src="require(`@/assets/${item.image}`)"
              width="50"
              height="50"
              contain
            ></v-img>
            <v-img
              v-else
              :src="require(`@/assets/svg/package.svg`)"
              width="50"
              height="50"
              contain
            ></v-img>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.price="{ item }">
            {{ parseFloat(item.price).toLocaleString() }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.total="{ item }">
            {{ parseFloat(item.price * item.quantity).toLocaleString() }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.quantity="{ item }">
            <v-text-field
              v-model="item.quantity"
              solo
              disabled
              dense
              class="mt-2 mb-2"
              type="number"
              hide-details
            ></v-text-field>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-icon size="small" @click="deleteItem(item)">
              {{ mdiDelete }}
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :text="$t('close')"
          variant="plain"
          @click="$emit('close')"
        ></v-btn>
        <v-spacer></v-spacer>
        <v-btn :text="$t('save')" variant="plain" @click="save"> </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCartMinus, mdiDelete } from "@mdi/js";
import fontMixin from "@/mixins/fontMixin";
import { mapState } from "vuex";

export default {
  name: "CartAdminDialog",
  props: ["modelDialog"],
  mixins: [fontMixin],
  data() {
    return {
      mdiCartMinus,
      mdiDelete,
      globalDiscount: 0,
      headers: [
        { title: "Produit", align: "start", sortable: false, key: "image" },
        {
          title: "Référence",
          align: "start",
          sortable: true,
          key: "product.reference"
        },
        {
          title: "Description",
          align: "start",
          sortable: true,
          key: "product.description"
        },
        {
          title: "Points",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "price"
        },
        {
          title: "Quantité",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "quantity"
        },
        {
          title: "Total",
          align: "start",
          sortable: true,
          width: "15vh",
          key: "total"
        },
        { title: "Action", align: "end", sortable: true, key: "actions" }
      ]
    };
  },
  computed: {
    ...mapState({
      editedItem: (state) => state.cart.editedItem,
      cart: (state) => state.cart.items.cartItems
    }),
    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    discountRate: {
      get() {
        return this.editedItem.discountRate;
      },
      set(value) {
        this.$store.commit("cart/setDiscountRate", value);
      }
    }
  },
  methods: {
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.cart-item-remove-confirm"))) {
        this.$store
          .dispatch("cart/deleteItem", item.cartItemId)
          .then(() => {
            const index = this.cart.findIndex(
              (cartItem) => cartItem.cartItemId === item.cartItemId
            );
            if (index !== -1) {
              this.cart.splice(index, 1);
            }
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.cart-item-removed"),
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.cart-item-removed"),
              color: "error"
            });
          });
      }
    },
    save() {
      const data = {
        customerId: this.editedItem.customerId,
        discount: this.editedItem.discountRate
      };
      window.confirm("Voulez-vous vraiment appliquer cette remise ?")
        ? this.$store.dispatch("cart/applyDiscount", data).then(() => {
            this.$store.commit("cart/setCustomer", "");
            this.$store.commit("cart/setDiscountRate", 0);
            this.$emit("close");
          })
        : null;
    }
  }
};
</script>
