import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/statsContract${paginationString.text}`);
    } else {
      return axios.get("/api/statsContract");
    }
  },
  getByCustomerId(id) {
    return axios.get(`/api/statsContract/customer/${id}`);
  },
  recalculatePoints(id) {
    return axios.post(`/api/statsContract/recalculate/${id}`);
  },
  updatePoints(data) {
    return axios.post("api/statsContract/updatePoints", data);
  }
};
