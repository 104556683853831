<template>
  <v-container fluid class="container-view">
    <v-card>
      <v-card-title :style="fontStyles.fontFamilyFutura" class="pb-5">
        <div class="pb-2">Plan du site</div>
        <v-divider></v-divider>
      </v-card-title>
      <v-card-text>
        <div :style="fontStyles.fontFamilyHumanist" class="pb-5">
          <div class="pb-3">IN DEVELOPMENT</div>
        </div>
        <div :style="fontStyles.fontFamilyFuturaButton">
          <div>Dernières mises à jour février/2025</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "SiteMapView",
  mixins: [fontMixin],
  data() {
    return {
      // data
    };
  },
  methods: {
    // methods
  },
  mounted() {
    // mounted
  }
};
</script>
