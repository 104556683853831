<template>
  <v-container class="container-view" fluid>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <h1 :style="fontStyles.fontFamilyFutura">
        {{ this.customer.name }}
      </h1>
    </v-row>
    <v-row class="d-flex align-center justify-center flex-wrap text-center">
      <div class="text-h6 font-weight-medium">
        <span :style="fontStyles.fontFamilyEgyptienne">
          Statut
          {{
            this.customer.status.number == "" ||
            this.customer.status.number < 0 ||
            this.customer.status.number == null
              ? "0"
              : this.customer.status.number
          }}
          -
        </span>
        <span class="color-point" :style="fontStyles.fontFamilyEgyptienne">
          {{
            this.stats.totalPoints == 0 || this.stats.totalPoints < 0
              ? 0
              : Math.ceil(this.stats.totalPoints).toLocaleString("fr-FR")
          }}
          points restants
        </span>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card elevation="6" style="border-radius: 0px">
          <v-card-title
            class="title-background"
            :style="fontStyles.fontFamilyFuturaSubTitle"
          >
            {{ $t("my-last-one-stryker-commands") }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items="commandes"
              :headers="headers"
              show-expand
              single-expand
              item-value="id"
              v-model:expanded="expanded"
              :no-data-text="$t('no-data')"
              :style="fontStyles.fontFamilyHumanist"
              :hide-default-footer="commandes.length >= 0"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.totalAmount="{ item }">
                {{ parseFloat(item.totalAmount).toLocaleString() }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.data-table-expand="{ item }">
                <v-icon
                  @click="toggleExpand(item.id)"
                  :loading="loadingDetails && selectedOrder === item.id"
                  >{{
                    expanded.includes(item.id) ? mdiChevronUp : mdiChevronDown
                  }}
                </v-icon>
              </template>

              <!-- estlint-disable-next-line -->
              <template v-slot:expanded-row="{ columns, item }">
                <tr v-if="expanded.includes(item.id)">
                  <td :colspan="columns.length">
                    <v-sheet elevation="9" class="mt-3 mb-3">
                      <v-table>
                        <thead>
                          <tr class="grey lighten-4">
                            <th>{{ $t("reference") }}</th>
                            <th>{{ $t("description") }}</th>
                            <th>{{ $t("price") }}</th>
                            <th>{{ $t("quantity") }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(product, index) in details"
                            :key="index"
                            class="grey lighten-4"
                          >
                            <td>
                              {{ product.reference }}
                            </td>
                            <td>
                              {{ product.description }}
                            </td>
                            <td>
                              {{ product.unitPrice }}
                            </td>
                            <td>
                              {{ product.quantity }}
                            </td>
                          </tr>
                        </tbody>
                      </v-table>
                    </v-sheet>
                  </td>
                </tr>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-tooltip location="top" :text="this.$t('invoice-download')">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      class="me-2"
                      size="large"
                      @click="downloadInvoice(item)"
                    >
                      {{ mdiDownloadBox }}
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="pl-0">
        <div id="chart">
          <apexchart
            class="apex-chart"
            type="donut"
            width="500"
            :options="chartOptions"
            :series="stats.totals"
          ></apexchart>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import points from "@/mixins/points";
import fontMixin from "@/mixins/fontMixin";
import { mdiDownloadBox, mdiChevronUp, mdiChevronDown } from "@mdi/js";

export default {
  name: "StrykerOneCommands",
  mixins: [points, fontMixin],
  data() {
    return {
      mdiDownloadBox,
      mdiChevronUp,
      mdiChevronDown,
      expanded: [],
      selectedOrder: null, // Stocke l'ID en attente d'expansion
      loadingDetails: false, // Indique si on attend les détails
      headers: [
        {
          title: this.$t("admin.customer-name"),
          align: "start",
          sortable: false,
          key: "customer.name"
        },
        { title: this.$t("order-number"), key: "orderNumber" },
        { title: this.$t("total"), key: "totalAmount" },
        {
          title: this.$t("actions"),
          align: "center",
          key: "actions",
          sortable: false
        }
      ],
      chartOptions: {
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: true
        },
        labels: ["NON ALLOCATED"],
        colors: [
          "#FFB300",
          "#B66A00",
          "#80BDB8",
          "#0092E2",
          "#22605B",
          "#397D7D",
          "#005C87",
          "#DDD8D9",
          "#858482",
          "#3761C2",
          "#532571",
          "#B6743A"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ],
        legend: {
          position: "right",
          offsetY: 0
        }
      }
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.users.selectedCustomer,
      stats: (state) => state.statsCustomer.statsCustomer
    }),
    pageCount() {
      return Math.ceil(this.commandes.length / this.itemsPerPage);
    },
    commandes: {
      get() {
        return this.$store.state.ordersOneStryker.items;
      }
    },
    details: {
      get() {
        return this.$store.state.ordersOneStryker.details;
      }
    }
  },
  watch: {
    selectedOrder(newOrder) {
      if (newOrder !== null) {
        this.getDetails(newOrder);
      }
    },
    stats: {
      handler(newStats) {
        this.chartOptions.labels = newStats.names;
      },
      immediate: true
    }
  },
  methods: {
    getDetails(orderNumber) {
      if (!orderNumber) return;
      this.loadingDetails = true;
      this.$store
        .dispatch("ordersOneStryker/getDetails", orderNumber)
        .then(() => {
          this.expanded = [orderNumber]; // Développe uniquement après l'appel API
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },
    toggleExpand(orderNumber) {
      if (this.loadingDetails) return;

      if (this.expanded.includes(orderNumber)) {
        this.expanded = [];
        this.$store.commit("ordersOneStryker/setDetails", []);
      } else {
        this.expanded = [orderNumber]; // Développe immédiatement
        this.selectedOrder = orderNumber; // Déclenche l'appel API
      }
    },
    downloadInvoice(item) {
      if (item.id) {
        this.$store
          .dispatch("ordersOneStryker/downloadInvoice", item.id)
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/pdf"
            });
            // Create a link element
            const link = document.createElement("a");
            // Set the download attribute with the desired file name
            link.download = "Facture.pdf";

            // Create a URL for the Blob and set it as the link's href
            link.href = window.URL.createObjectURL(blob);

            // Append the link to the document
            document.body.appendChild(link);

            // Trigger a click on the link to start the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
          });
      } else {
        this.$store.dispatch("snackbar/show", {
          message: this.$t("errors.missing-order-information"),
          color: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.apex-chart {
  padding-top: 50px;
}
</style>
