import axios from "axios";

export default {
  get(paginationString) {
    if (paginationString) {
      return axios.get(`/api/customers${paginationString.text}`);
    } else {
      return axios.get("/api/customers");
    }
  },
  getCustomerWithDetails(id) {
    return axios.get(`/api/customers/${id}/WithDetails`);
  },
  store(customer) {
    return axios.post("/api/customers", customer);
  },
  delete(id) {
    return axios.delete(`/api/customers/${id}`);
  },
  update(id, customer) {
    return axios.put(`/api/customers/${id}`, customer);
  },
  getLinkedUsers(id) {
    return axios.get(`/api/customers/${id}/users`);
  },
  saveLinkedUsers(data) {
    return axios.post(`/api/customers/${data.id}/users`, data.users);
  }
};
