<template>
  <v-container fluid class="px-0 py-0">
    <v-footer color="#545857">
      <v-row no-gutters class="text-left d-flex flex-column">
        <div class="text-center">
          <!-- <v-btn
            v-for="icon in icons"
            :key="icon"
            :icon="icon"
            class="mx-4"
            variant="text"
          ></v-btn> -->
        </div>
        <div>
          <v-row>
            <v-col cols="8" class="center mt-4">
              <v-btn
                v-for="link in links"
                :key="link.text"
                class="mx-2"
                color="white"
                rounded="xl"
                variant="text"
                :style="fontStyles.fontFamilyFuturaButton"
                @click="openUrl(link)"
              >
                {{ link.text }}
              </v-btn>
            </v-col>

            <v-col
              class="text-left mt-4"
              cols="4"
              :style="fontStyles.fontFamilyEgyptienneSubTitle"
            >
              <div>
                Stryker France, société par actions simplifiée au capital social
                de 27 495 520 €
              </div>
              <div>
                immatriculée au R.C.S. de Lyon sous le numéro 333 710 275 et
                domiciliée
              </div>
              <div>ZAC - Avenue Satolas Green</div>
              <div>69330 Pusignan - France</div>
              <div>+33 (0) 472 453 600</div>
            </v-col>
          </v-row>
        </div>
        <div class="text-center" :style="fontStyles.fontFamilyHumanist">
          <v-col class="text-center mt-4" cols="12">
            © <strong>Stryker France</strong>
            {{ new Date().getFullYear() }}
          </v-col>
        </div>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import fontMixin from "@/mixins/fontMixin";
export default {
  name: "FooterComponent",
  mixins: [fontMixin],
  data() {
    return {
      links: [
        {
          text: this.$t("footer.confidentiality"),
          url: "privacy"
        },
        {
          text: this.$t("footer.accessibility-statement"),
          url: "accessibility"
        },
        {
          text: this.$t("footer.warning-health-pro"),
          url: "disclaimer"
        },
        {
          text: this.$t("footer.terms-of-use"),
          url: "terms-of-use"
        },
        {
          text: this.$t("footer.site-map"),
          url: "sitemap"
        },
        {
          text: this.$t("footer.product-exp"),
          url: "",
          path: "https://www.stryker.com/productexperience/Legal/Agreement"
        },
        {
          text: this.$t("footer.ethics-helplines"),
          url: "",
          path: "https://app.convercent.com/fr-fr/Anonymous/IssueIntake/LandingPage/b6bb4e84-9fcb-ea11-a974-000d3ab9f296"
        }
      ]
    };
  },
  methods: {
    openUrl(link) {
      if (link.url === "") {
        window.open(link.path, "_blank");
      } else {
        this.$router.push({ name: link.url });
      }
    }
  },
  created() {}
};
</script>

<style>
.align-stryker {
  align-content: center;
}
</style>
