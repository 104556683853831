import email from "../../api/email";

export default {
  namespaced: true,
  state: {
    loading: false
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    sendEmail({ commit }, data) {
      return new Promise((resolve, reject) => {
        email
          .sendEmail(data)
          .then((response) => {
            commit("setLoading", false);
            resolve(response);
          })
          .catch((error) => {
            commit("setLoading", false);
            reject(error);
          });
      });
    }
  },
  getters: {}
};
