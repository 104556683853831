<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="products"
      :items-length="totalItems"
      :search="search"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiPackageVariantClosedPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            Nouveau Produit
          </v-btn>
          <import-file-dialog :table="'products'"></import-file-dialog>
          <v-tooltip
            location="top"
            text="Recalculer les colonnes personnalisées"
          >
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                class="mb-2 mr-2"
                color="#ffb500"
                size="large"
                @click="recalculateColumns"
              >
                {{ mdiRefresh }}
              </v-icon>
            </template>
          </v-tooltip>
        </v-toolbar>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'products'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-product
      :modelDialog="productDialog"
      @close="productDialog = false"
    ></add-edit-product>
  </v-container>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiPackageVariantClosedPlus,
  mdiRefresh
} from "@mdi/js";
import AddEditProduct from "./admin/AddEditProduct.vue";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import ImportFileDialog from "./ImportFileDialog.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "ProductAdmin",
  components: {
    ImportFileDialog,
    PaginationDynamic,
    AddEditProduct
  },
  mixins: [paginate],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      mdiPackageVariantClosedPlus,
      mdiRefresh,
      productDialog: false,
      headers: [
        {
          title: this.$t("admin.reference"),
          align: "start",
          key: "reference"
        },
        { title: this.$t("admin.gimID"), key: "gimID" },
        { title: this.$t("admin.description"), key: "description" },
        { title: this.$t("admin.price"), key: "price" },
        { title: this.$t("admin.quantity"), key: "quantity" },
        { title: this.$t("admin.lppr"), key: "lppr" },
        { title: this.$t("admin.current-cost"), key: "currentCost" },
        { title: this.$t("actions"), key: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount
    }),
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    recalculateColumns() {
      window.confirm(
        "Voulez-vous vraiment recalculer les colonnes personnalisées ?"
      ) &&
        this.$store
          .dispatch("products/recalculateColumns")
          .then((response) => {
            this.$store.dispatch("showSnackbar", {
              message: response.data,
              color: "success"
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
    toSort(item) {
      this.sortBy(item, "products");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "products");
    },
    openDialog() {
      this.$store.commit("products/setEditedId", -1);
      this.$store.commit(
        "products/setEditedItem",
        Object.assign({}, this.$store.state.products.defaultItem)
      );
      this.productDialog = true;
    },
    editItem(item) {
      this.$store.commit("products/setEditedId", item.id);
      this.$store.commit("products/setEditedItem", Object.assign({}, item));
      this.productDialog = true;
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.product"))) {
        this.$store
          .dispatch("products/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.product-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "products");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.product-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant ici */
</style>
