<template>
  <v-dialog v-model="dialog" max-width="1400">
    <v-card style="border-radius: 0px">
      <v-card-title :style="fontStyles.fontFamilyFuturaSubTitle">
        <v-icon :icon="mdiNoteText"></v-icon>
        {{ $t("order-recap") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="12"
            lg="8"
            sm="12"
            xl="8"
            smAndDown="12"
            offset-xl="0"
            offset-lg="0"
            offset-sm="0"
            offset-md="0"
          >
            <v-table
              height="300px"
              fixed-header
              :style="fontStyles.fontFamilyHumanist"
            >
              <thead>
                <tr>
                  <th class="text-left">
                    {{ this.$t("reference") }}
                  </th>
                  <th class="text-left">
                    {{ this.$t("description") }}
                  </th>
                  <th class="text-left">
                    {{ this.$t("points") }}
                  </th>
                  <th class="text-left">
                    {{ this.$t("quantity") }}
                  </th>
                  <th class="text-left">
                    {{ this.$t("total") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in cart" :key="item.product.reference">
                  <td>{{ item.product.reference }}</td>
                  <td>{{ item.product.description }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>
                    {{
                      parseFloat(item.price * item.quantity).toLocaleString()
                    }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
          <v-col
            cols="12"
            md="12"
            lg="4"
            sm="12"
            xl="4"
            smAndDown="12"
            offset-xl="0"
            offset-lg="0"
            offset-sm="0"
            offset-md="0"
          >
            <v-card style="border-radius: 0px" elevation="4" class="mx-auto">
              <v-card-text>
                <div class="pb-0">
                  <span :style="fontStyles.fontFamilyEgyptienne">
                    {{ this.$t("admin.total-points") }} :
                  </span>
                  <span
                    class="color-point"
                    :style="fontStyles.fontFamilyEgyptienneSubTitle"
                  >
                    {{
                      this.stats.totalPoints == 0 || this.stats.totalPoints < 0
                        ? 0
                        : Math.ceil(this.stats.totalPoints).toLocaleString(
                            "fr-FR"
                          )
                    }}
                  </span>
                </div>
                <div class="pb-3" :style="fontStyles.fontFamilyEgyptienne">
                  <span> {{ this.$t("admin.total-quantity") }} : </span>
                  {{ this.totalQuantity() }}
                </div>
                <div>
                  <span :style="fontStyles.fontFamilyFuturaSubTitle">
                    {{ this.$t("total") }} :
                  </span>
                  <span :style="fontStyles.fontFamilyEgyptienneSubTitle">
                    {{ this.totalProduct("string") }} points
                  </span>
                </div>
                <div v-if="this.stats.totalPoints < this.totalProduct()">
                  <span :style="fontStyles.fontFamilyFuturaSubTitle">
                    {{ this.$t("remaining-amount-to-pay") }} :
                  </span>
                  <span
                    v-if="this.discount == 0"
                    :style="fontStyles.fontFamilyEgyptienneSubTitle"
                  >
                    {{ this.remainingAmountToPay() }} € HT
                  </span>
                  <span v-else :style="fontStyles.fontFamilyEgyptienneSubTitle">
                    <span style="text-decoration: line-through; color: red">
                      {{ this.remainingAmountToPay() }} € HT
                    </span>
                    <span>
                      {{
                        (
                          Number(
                            this.remainingAmountToPay()
                              .replace(/\s/g, "")
                              .replace(",", ".")
                          ) -
                          this.discount *
                            0.01 *
                            Number(
                              this.remainingAmountToPay()
                                .replace(/\s/g, "")
                                .replace(",", ".")
                            )
                        ).toLocaleString()
                      }}
                      € HT
                    </span>
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="6">
            <div :style="fontStyles.fontFamilyEgyptienneSubTitle">
              {{ this.$t("delivery-address-selected") }} :
              {{ deliveryAddressObj.fullAddress }}
            </div>
            <div :style="fontStyles.fontFamilyEgyptienneSubTitle">
              {{ this.$t("billing-address-selected") }} :
              {{ billingAddressObj.fullAddress }}
            </div>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="acceptTerms" color="#ffb500">
              <template v-slot:label>
                <div :style="fontStyles.fontFamilyEgyptienneSubTitle">
                  {{ this.$t("accept-cgv") }}
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props }">
                      <a
                        href="https://www.onesyk.fr/public/CGV%20Stryker%20France_01012025.pdf"
                        target="_blank"
                        v-bind="props"
                        @click.stop
                      >
                        {{ this.$t("cgv") }})
                      </a>
                    </template>
                    {{ this.$t("new-window") }}
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="close"
          rounded="0"
          variant="elevated"
          color="#395d5b"
          :style="fontStyles.fontFamilyFuturaButton"
        >
          {{ $t("close") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!acceptTerms"
          variant="elevated"
          @click="order"
          rounded="0"
          color="#395d5b"
          :style="fontStyles.fontFamilyFuturaButton"
        >
          {{ $t("proceed-to-checkout") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiNoteText } from "@mdi/js";
import { mapState } from "vuex";
import points from "@/mixins/points";
import fontMixin from "@/mixins/fontMixin";

export default {
  name: "ValidateOrderDialog",
  props: ["modelDialog", "deliveryAddress", "billingAddress"],
  mixins: [points, fontMixin],
  data() {
    return {
      mdiNoteText,
      acceptTerms: false
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.items.cartItems,
      addresses: (state) => state.addresses.items,
      discount: (state) => state.cart.editedItem.discountRate,
      stats: (state) => state.statsCustomer.statsCustomer
    }),

    dialog: {
      get() {
        return this.modelDialog;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    deliveryAddressObj() {
      return this.addresses.find(
        (address) => address.id === this.deliveryAddress
      );
    },

    billingAddressObj() {
      return this.addresses.find(
        (address) => address.id === this.billingAddress
      );
    }
  },
  methods: {
    totalProduct(type) {
      if (type == "string") {
        return this.cart
          .reduce((acc, item) => acc + item.price * item.quantity, 0)
          .toLocaleString();
      } else {
        return this.cart
          .reduce((acc, item) => acc + item.price * item.quantity, 0)
          .toFixed(2);
      }
    },
    totalQuantity() {
      return this.cart.reduce((acc, item) => acc + item.quantity, 0);
    },
    remainingAmountToPay() {
      return (this.totalProduct() - this.stats.totalPoints).toLocaleString();
    },
    order() {
      this.$store.commit("setOverlay", true);
      this.$store
        .dispatch("ordersOneStryker/createOrder", {
          customerId: this.$store.state.users.selectedCustomer.id,
          fK_ShippingAddressId: this.deliveryAddress,
          fK_BillingAddressId: this.billingAddress
        })
        .then(() => {
          this.$store.commit("setOverlay", false);
          this.$store.dispatch("showSnackbar", {
            message: "Commande validée",
            color: "success"
          });
          this.close();
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$store.commit("setOverlay", false);
          this.$store.dispatch("showSnackbar", {
            message: "Erreur lors de la validation de la commande",
            color: "error"
          });
        });
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
