import axios from "axios";

export default {
  get() {
    return axios.get("/api/priceList");
  },
  getPriceListByCustomerId(customerId) {
    return axios.get(`/api/priceList/customer/${customerId}`);
  },
  store(priceList) {
    return axios.post("/api/priceList", priceList);
  },
  delete(id) {
    return axios.delete(`/api/priceList/${id}`);
  },
  update(id, priceList) {
    return axios.put(`/api/priceList/${id}`, priceList);
  },
  getPriceListProductByPriceListId(id) {
    return axios.get(`/api/priceList/${id}/details`);
  },
  updateProduct(id, price) {
    return axios.post(`/api/priceList/details/${id}`, price);
  }
};
