<template>
  <v-container fluid>
    <v-data-table-server
      :headers="headers"
      :items="addresses"
      :search="search"
      density="compact"
      :items-length="totalItems"
      :loading="loadingDatatables"
      @update:sortBy="toSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            density="compact"
            :label="this.$t('search')"
            class="pl-2"
            :prepend-inner-icon="mdiMagnify"
            @update:modelValue="toSearchBack"
            variant="solo-filled"
            flat
            hide-details
            single-line
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            class="mb-2 text-white mr-3"
            :prepend-icon="mdiAccountPlus"
            color="#ffb300"
            @click="openDialog"
            dark
          >
            {{ $t("admin.new-address") }}
          </v-btn>
        </v-toolbar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.addressType="{ item }">
        <v-chip color="#ffb300" dark>
          {{
            item.addressType === 0
              ? $t("billing-address")
              : $t("delivery-address")
          }}
        </v-chip>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)">
          {{ mdiPencil }}
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)">
          {{ mdiDelete }}
        </v-icon>
      </template>

      <template v-slot:bottom>
        <pagination-dynamic :store="'addresses'"></pagination-dynamic>
      </template>
    </v-data-table-server>
    <add-edit-address
      :modelDialog="addressDialog"
      @close="addressDialog = false"
    ></add-edit-address>
  </v-container>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify, mdiAccountPlus } from "@mdi/js";
import PaginationDynamic from "@/components/PaginationDynamic.vue";
import AddEditAddress from "./admin/AddEditAddress.vue";
import paginate from "@/mixins/paginations";
import { mapState } from "vuex";

export default {
  name: "AddressAdmin",
  components: { AddEditAddress, PaginationDynamic },
  mixins: [paginate],
  data() {
    return {
      mdiAccountPlus,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      dialog: false,
      dialogDelete: false,
      addressDialog: false,
      itemsPerPage: 15,
      page: 1,
      headers: [
        { title: "Numéro client", key: "customer.number" },
        { title: "Nom", key: "customer.name" },
        { title: "Type d'adresse", key: "addressType" },
        { title: "Ville", key: "city" },
        { title: "Pays", key: "country" },
        { title: "Adresse complète", key: "fullAddress" },
        { title: "Actions", key: "actions", sortable: false, align: "end" }
      ]
    };
  },
  computed: {
    ...mapState({
      addresses: (state) => state.addresses.items,
      loadingDatatables: (state) => state.loadingTables,
      totalItems: (state) => state.paginations.totalCount,
      editedId: (state) => state.addresses.editedId
    }),
    pageCount() {
      return Math.ceil(this.addresses.length / this.itemsPerPage);
    },
    search: {
      get() {
        return this.$store.state.paginations.search;
      },
      set(value) {
        this.$store.commit("paginations/setSearch", value);
      }
    }
  },
  methods: {
    toSort(item) {
      this.sortBy(item, "addresses");
    },
    toSearchBack(value, time = 500) {
      this.searchBack(value, time, "addresses");
    },
    openDialog() {
      this.$store.commit("addresses/setEditedId", -1);
      this.$store.commit(
        "addresses/setEditedItem",
        Object.assign({}, this.$store.state.addresses.defaultItem)
      );
      this.addressDialog = true;
    },
    editItem(item) {
      this.$store.commit("addresses/setEditedId", item.id);
      this.$store.commit("addresses/setEditedItem", Object.assign({}, item));
      this.addressDialog = true;
    },
    deleteItem(item) {
      if (confirm(this.$t("alert.delete.address"))) {
        this.$store
          .dispatch("addresses/deleteItem", item.id)
          .then(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.success.address-delete"),
              color: "success"
            });
            this.toSearchBack(this.search, 500, "addresses");
          })
          .catch(() => {
            this.$store.dispatch("showSnackbar", {
              message: this.$t("alert.error.address-delete"),
              color: "error"
            });
          });
      }
    }
  }
};
</script>
